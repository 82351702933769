<template>
  <ContainerCard :locationMsg="locationMsg" :width="100">
    <div class="School">
      <div class="School-search">
        <SchoolSearch
          :defaultValue="this.params.keyword"
          @searchHandle="searchHandle"
        ></SchoolSearch>
      </div>
      <div class="schools" v-loading="fullscreenLoading">
        <div
          class="schoolItem"
          v-for="(item, index) in List"
          :key="index"
          @click="toSchoolDetails(item.billid)"
        >
          <el-image
            class="logo"
            alt=""
            :fit="'fill'"
            :src="httpConfig.baseURL+item.logo || defaultImg"
            v-if="item.logo"
          />
          <div v-if="!item.logo" class="no-logobox">
              <el-image class="no-logo" :src=" item.logo || defaultImg " alt="" />
          </div>
          <div class="title">{{ item.name }}</div>
          <div v-if="item.techList.length > 0" class="labelGroup">
            <span
              class="label"
              v-for="(item, index) in item.techList"
              :key="item"
            >
              {{ "#" + item }}
            </span>
          </div>
          <div v-else style="margin-bottom: 40px"></div>
          <div class="line"></div>
          <div class="bottom">
            <div class="bottom-item">
              <span class="label">院校地址</span>
              <span class="value">{{ item.address }}</span>
            </div>
            <div class="bottom-item">
              <span class="label">联系电话</span>
              <span class="value">{{ item.mobile }}</span>
            </div>
            <!-- <img v-if="item.address" src="../../assets/images/导航地址@2x.png" alt="" class="icon" />
            <p class="text">{{ item.address }}</p> -->
          </div>
        </div>
      </div>
    </div>
    <div class="pagination">
      <Pagination
        @changeIndex="setCurrentPage"
        :total="total"
        :totalPage="totalPage"
        :pageSize="params.pageSize"
      ></Pagination>
    </div>
  </ContainerCard>
</template>
<script>
import Location from "../../components/Location.vue";
import ContainerCard from "../../components/newContainerCard.vue";
import Pagination from "../../components/Pagination.vue";
import SchoolSearch from "../School/components/schoolSearch.vue";
import { mapGetters } from "vuex";
import httpConfig from "../../common/utils/config";
import { getInstitutions } from "../../api/Institutions/Institutions"; //TODO: 少了logo
import defaultImg from "../../assets/images/暂无数据默认图片.png";
export default {
  computed: {
    ...mapGetters(["techfieldList"]),
  },
  data() {
    return {
      httpConfig,
      defaultImg,
      schoolItemList: [
        {
          img: "https://tse3-mm.cn.bing.net/th/id/OIP-C.eB4vx4YzYzQ54zhJwNgwDwHaE7?w=251&h=180&c=7&r=0&o=5&dpr=1.25&pid=1.7",
          name: "华侨大学",
          type: ["国家重点", "双一流", "本科"],
          site: "南中环体育路口",
          tel: "0595-1343xxx",
        },
        {
          img: "https://tse3-mm.cn.bing.net/th/id/OIP-C.eB4vx4YzYzQ54zhJwNgwDwHaE7?w=251&h=180&c=7&r=0&o=5&dpr=1.25&pid=1.7",
          name: "仰恩大学",
          type: ["本科"],
          site: "南中环体育路口",
          tel: "0595-1343xxx",
        },
        {
          img: "https://tse3-mm.cn.bing.net/th/id/OIP-C.eB4vx4YzYzQ54zhJwNgwDwHaE7?w=251&h=180&c=7&r=0&o=5&dpr=1.25&pid=1.7",
          name: "泉州师范学院",
          type: ["本科"],
          site: "南中环体育路口",
          tel: "0595-1343xxx",
        },
      ],
      locationMsg: [],
      total: 0,
      totalPage: 0,
      fullscreenLoading: false,
      List: [],
      params: {
        tech_field: "", //技术领域
        keyword: "",
        pageNo: 1,
        pageSize: 10,
      },
    };
  },
  created() {
    this.getData();
    // console.log("111",httpConfig.baseURL)
    // console.log("222",service.baseURL)
  },

  methods: {
    async getData() {
      console.log("参数是:",this.params)
      let that = this;
      that.fullscreenLoading = true;
      let filedList = {
        11:"省双一流建设高校",
        12:"省重点建设高校",
        13:"省高水平大学",
        21:"国家优质校",
        22:"国家高水平院校",
        23:"国家高水平专业群建设院校",
        24:"省高水平院校",
        25:"省高水平专业群建设院校",
        26:"省级示范性院校",
        31:"国家级示范学校",
        32:"国家级重点校",
        33:"省高水平院校",
        34:"省高水平专业建设院校",
        35:"省级规范化学校",
        36:"达标校",
      };
      let res = await getInstitutions(that.params);
      that.List = res.data.items.map((item) => {
        let tmp = item;
        tmp.techList = [];
        // console.log(tmp.tech_field)
        Object.keys(filedList).forEach(function (key) {
          if (
            tmp?.school_type_ext &&
            tmp?.school_type_ext.toString().split(",").indexOf(key) > -1
          ) {
            tmp.techList.push(filedList[key]);
          }
        });
        return tmp;
      });
      console.log("List的数据:",this.List)
      this.total = res.data.totalCount;
      // this.totalPage = res.data.totalPage
      this.totalPage = Math.ceil(res.data.totalCount / this.params.pageSize);
      that.fullscreenLoading = false;
    },
    // 搜索
    searchHandle(val) {
      console.log("学校输入的值为:",val)
      this.params.pageNo = 1;
      this.params.keyword = val;
      this.getData();
    },
    toSchoolDetails(id) {
      this.$router.push({ path: "/school/schoolDetails", query: { id: id } });
    },
    // 分页
    setCurrentPage(val) {
      this.params.pageNo = val;
      this.getData();
    },
  },
  components: { Pagination, ContainerCard, Location , SchoolSearch },
};
</script>
<style scoped lang="scss">
::v-deep .AppMain {
  background-color: #f6f6f6;
}
.pagination {
  height: 138px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6f6f6f6;
}
.School {
  width: 100%;
  padding-top: 22px;
  background-color: #f6f6f6;
}
.School-search {
  display: flex;
  justify-content: center;
}
.schools {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  /* padding: 20px; */
  grid-gap: 20px 20px;
  width: 1120px;
  padding: 0px 30px;
}
.schoolItem {
  width: 224px;
  height: 288px;
  box-sizing: border-box;
  background: #ffffff;
  padding: 20px;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 12px 40px rgba(0, 0, 0, 0.12);
  }
  .logo {
    width: 184px;
    height: 110px;
    border-radius: 3px;
    margin-bottom: 20px;
  }
  .no-logobox{
    width: 184px;
    height: 110px;
    background: #F1F1F1;
    border-radius: 3px;
    margin-bottom: 20px;
    text-align: center;
    line-height: 110px;
    .no-logo{
      width: 24px;
      height: 24px;
    }
  }

  .title {
    font-size: 16px;
    font-family: 思源黑体 CN Medium;
    color: #101010;
    line-height: 24px;
    font-weight: 600;
    display: inline-block;
    width: 184px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .labelGroup {
    height: 24px;
    width: 184px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .label {
      font-size: 14px;
      font-family: 思源黑体 CN Regular;
      color: #2885ff;
      line-height: 21px;
      margin-right: 6px;
      margin-top: 6px;
    }
  }

  .line {
    width: 184px;
    height: 1px;
    color: #f1f1f1;
  }

  .bottom {
    width: 184px;
    display: flex;
    flex-direction: column;
    .bottom-item {
      margin-bottom: 6px;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      .label {
        font-size: 12px;
        font-family: 思源黑体 CN Regular;
        color: #8896a7;
        margin-right: 12px;
        line-height: 18px;
        display: inline-block;
      }
      .value {
        font-size: 12px;
        font-family: 思源黑体 CN Regular;
        color: #404040;
        line-height: 18px;
        display: inline-block;
        width: 111px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .right {
    // flex: 1;
    // margin-left: 14px;

    .name {
      font-size: 28px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #101010;
      // line-height: 24px;
      line-height: 38px;
    }
    .labelGroup {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .label {
        margin-top: 14px;
        margin-right: 8px;
        border-radius: 8px;
        border: 1px solid #3e9cff;
        padding: 7px 10px;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #3e9cff;
      }
    }
    .tel {
      margin-top: 20px;
      .icon {
        height: 16px;
        width: 16px;
        margin-right: 8px;
      }
      .text {
        font-size: 16px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #101010;
      }
    }
  }
  hr {
    margin-top: 20px;
    flex: 1;
    width: 100%;
    height: 1px;
    border: none;
    border-bottom: 1px solid #eaeaea;
  }
  .bottom {
    display: flex;
    margin-top: 16px;
    .icon {
      height: 16px;
      width: 16px;
      margin-right: 8px;
    }
    .text {
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      line-height: 24px;
      color: #666666;
    }
  }
}

@media screen and (max-width: 1100px) {
}

@media screen and (max-width: 768px) {
}
</style>