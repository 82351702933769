import request from '../../common/utils/request'

// 获取学院详情
export function getIndustryById(data) {
  return request({
    // url: "	/itf/common/cjrh/institution/getInstitutionById.json",
    url:"/itf/common/cjrh/institution/getInstitutionById.json",
    method: "get",
    params: data
  });
}


// 翻页查找
export function getInstitutions(data) {
  return request({
    url: "/itf/common/cjrh/institution/getInstitutions.json",
    method: "get",
    params: data
  });
}


